export default {
  'Deutsch': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/de_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/de_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/de_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/de_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/de_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'English': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/en_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/en_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': [
      {
        'preview': new URL('../assets/posters/en_11x17inch_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_11x17inch_news_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/en_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/en_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/en_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Español (Latam)': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/es_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/es_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/es_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/es_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/es_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Français': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/fr_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/fr_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fr_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/fr_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Italiano': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/it_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/it_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/it_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/it_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/it_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Kiswahili': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/swa_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/swa_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/swa_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/swa_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/swa_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Nederlands': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/nl_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/nl_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/nl_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/nl_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/nl_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Português (Brasil)': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/pt_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/pt_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/pt_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/pt_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Suomalainen': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/fi_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/fi_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/fi_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/fi_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/fi_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'български': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/bgr_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/bgr_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/bgr_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/bgr_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/bgr_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'Ελληνική': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/gr_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/gr_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/gr_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/gr_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/gr_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/gr_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/gr_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  '日本語': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/jp_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/jp_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/jp_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/jp_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  '한국어': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/ko_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/ko_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ko_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/ko_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'عربي': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/ar_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/ar_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/ar_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/ar_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/ar_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
  'עִברִית': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/heb_297x420mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_297x420mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_297x420mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_297x420mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_297x420mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_297x420mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_297x420mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_297x420mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_297x420mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_297x420mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_297x420mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_297x420mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_297x420mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_297x420mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_297x420mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_297x420mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_297x420mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_297x420mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_297x420mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/heb_297x420mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_297x420mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
    '700x1000mm': [
      {
        'preview': new URL('../assets/posters/heb_700x1000mm_overall.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_700x1000mm_overall.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_700x1000mm_overall.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_700x1000mm_benefits_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_700x1000mm_benefits_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_700x1000mm_benefits_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_700x1000mm_benefits_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_700x1000mm_benefits_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_700x1000mm_benefits_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_700x1000mm_dialogue_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_700x1000mm_dialogue_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_700x1000mm_dialogue_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_700x1000mm_dialogue_testimonial_b.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_700x1000mm_dialogue_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_700x1000mm_dialogue_testimonial_b.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_700x1000mm_news_testimonial_a.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/heb_700x1000mm_news_testimonial_a.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_700x1000mm_news_testimonial_a.pdf', import.meta.url),
      },
      {
        'preview': new URL('../assets/posters/heb_700x1000mm_news_testimonial_b.jpg', import.meta.url),
        'thumbnail':  new URL('../assets/posters/heb_700x1000mm_news_testimonial_b.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/heb_700x1000mm_news_testimonial_b.pdf', import.meta.url),
      },
    ],
  },
};
